var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("breadcrumb-header", {
        attrs: { labels: ["New project"], go_back_to: "management_projects" },
      }),
      _c(
        "div",
        { staticClass: "inner" },
        [_c("projects-new", { on: { created: _vm.onProjectCreated } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }